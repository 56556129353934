
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { VueCookieNext } from "vue-cookie-next";
import { apiEndpoint } from "@/mixin/apiMixin.js";
import ApiService from "@/core/services/ApiService";
export default defineComponent({
  mixins: [apiEndpoint],
  name: "kt-user-menu",
  components: {},
  data() {
    return {
      api_url: "",
      userInfo: {
        _seip_employee_id: "" as any,
      },
      employee_name: "" as any,
      employee_email: "" as any,
      employee_id: "" as any,

      employee_image: "" as any,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    this.userInfo = VueCookieNext.getCookie("_seip_user");
    this.employee_id = VueCookieNext.getCookie("_seip_employee_id");
    this.emitter.on("employee_update_done", async (data) => {
      await this.getEmployeeProfile();
    });
    await this.getEmployeeProfile();
  },

  methods: {
    async getEmployeeProfile() {
      await ApiService.get("configurations/employee/show/" + this.employee_id)
        .then((response) => {
          this.employee_image = response.data.data.attach_file_info?.file_path;
          this.employee_name = response.data.data.name;
          this.employee_email = response.data.data.email;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();

    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";

    const countries = {
      en: {
        flag: "media/flags/united-states.svg",
        name: "English",
      },
      es: {
        flag: "media/flags/spain.svg",
        name: "Spanish",
      },
      de: {
        flag: "media/flags/germany.svg",
        name: "German",
      },
      ja: {
        flag: "media/flags/japan.svg",
        name: "Japanese",
      },
      fr: {
        flag: "media/flags/france.svg",
        name: "French",
      },
    };

    const signOut = () => {
      const cookies = VueCookieNext.keys(); // Get all cookies
      for (const cookieName of cookies) {
        if (VueCookieNext.removeCookie(cookieName)) {
          console.log('Cookie removed: ' + cookieName);
        } else {
          console.log('Error removing cookie: ' + cookieName);
        }
      }
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: 'sign-in' }));
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
    };
  },
});


import { defineComponent } from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";
import KTTopbarButton from "@/layout/header/TopBarButton.vue";
import KTMenu from "@/layout/header/Menu.vue";
import { VueCookieNext } from "vue-cookie-next";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import ApiService from "@/core/services/ApiService";


import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
    KTMenu,
    KTTopbarButton,
    KTNotificationsMenu
  },
   data() {
    return {
      show: false,
      loading: false,
        taskList: [] as any,
        initData:[] as any,
        taskKeyUpdate: 0,
        search: '',
        componentKey:0,
        user:[] as any,
        employee_id: '' as any,
        role_id:'' as any,
        all_cookies: '' as any,
        filterStatus: '' as any,
        deadline:'' as any,
        warning: false,
        notificationCount: '' as any,
        notificationReset: '' as any,
    }
   },

  async created() {
    await this.getNotificationList();
    let today = new Date().toISOString().slice(0, 10)
    if (today <= '2023-03-09'){
      if (VueCookieNext.getCookie("women_popup") == 1) {
        this.show = false;
      }else{
         this.show = true;
      }
      
    }
    setTimeout(() => (this.show = false), 10000);
  
  },
  methods: {
    async hide() {
      this.show = false;
       VueCookieNext.setCookie("women_popup", '1');
    },
    async getNotificationList() {    
        this.employee_id= VueCookieNext.getCookie("_seip_employee_id");      
        await ApiService.get("configurations/notification/count?employee_id="+ this.employee_id)
          .then((response) => {
            this.notificationCount = response.data.data;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      },
      async resetNotication(){
        this.employee_id= VueCookieNext.getCookie("_seip_employee_id");      
        await ApiService.get("configurations/notification/reset?employee_id="+ this.employee_id)
          .then((response) => {
            this.notificationReset = response.data.data;
            this.notificationCount = 0;
          })
          .catch(({ response }) => {
            console.log(response);
          });
        
      },
  },
  setup() {
    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
    };
  },
});


import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import { VueCookieNext } from "vue-cookie-next";

export default defineComponent({
  name: "notifications-menu",
  components: {},
  data() {
      return {
        loading: false,
        taskList: [] as any,
        initData:[] as any,
        taskKeyUpdate: 0,
        search: '',
        componentKey:0,
        user:[] as any,
        employee_id: '' as any,
        role_id:'' as any,
        all_cookies: '' as any,
        filterStatus: '' as any,
        deadline:'' as any,
        warning: false,
        notificationList: '' as any,
        notificationListRead: '' as any,
      };
    },
  async created() {
    this.role_id= VueCookieNext.getCookie("_seip_role_id");
    await this.getNotificationList()
    setInterval(() => {
      this.getNotificationList();
  }, 50000);
  this.role_id= VueCookieNext.getCookie("_seip_role_id");
  },
    methods: {
      async getNotificationList() {    
        this.employee_id= VueCookieNext.getCookie("_seip_employee_id");  
        await ApiService.get("configurations/notification/list?employee_id="+ this.employee_id)
          .then((response) => {
            this.notificationList = response.data.data;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      },
      async readNotificationList(item) {    
        this.employee_id= VueCookieNext.getCookie("_seip_employee_id");  
        await ApiService.get("configurations/notification/read?employee_id="+ this.employee_id + "&id="+item)
          .then((response) => {
            this.notificationListRead = response.data.data;
            console.log(this.notificationListRead);
          })
          .catch(({ response }) => {
            console.log(response);
          });
      },
    },
    
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-stretch flex-shrink-0" }
const _hoisted_2 = {
  class: "d-flex align-items-center ms-1 ms-lg-3",
  id: "kt_header_user_menu_toggle"
}
const _hoisted_3 = {
  class: "cursor-pointer symbol symbol-30px d-flex",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom"
}
const _hoisted_4 = { class: "mt-3 mr-3" }
const _hoisted_5 = { class: "mt-5 mb-0" }
const _hoisted_6 = { class: "text-end" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h6", _hoisted_5, _toDisplayString(this.employee_name), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(this.userInfo?.employee?.designation
                      ? this.userInfo?.employee?.designation
                      :  this.userInfo?.employee?.entity_organogram[0]?.entitytyperole
                        ?.role_title), 1)
        ]),
        _createElementVNode("img", {
          class: "my-auto",
          src: 
            this.employee_image
              ? _ctx.api_url + this.employee_image
              : 'media/avatars/blank.png'
          
        }, null, 8, _hoisted_7)
      ]),
      _createVNode(_component_KTUserMenu)
    ])
  ]))
}

import { defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { MenuComponent } from "@/assets/ts/components";
import MainMenuConfig from "@/core/config/Module";
import { headerMenuIcons } from "@/core/helpers/config";
import { version } from "@/core/helpers/documentation";
import ApiService from "@/core/services/ApiService";
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  name: "KTMenu",
  components: {},
  data() {
    return {
      MainMenu: [] as any,
      userInfo: [] as any,
      role_id: "" as any,
     
    };
  },
  async created() {
    await this.TopVar();
  },
  methods: {
    async TopVar() {
      const store = useStore();
      this.role_id = VueCookieNext.getCookie('_seip_role_id');
      ApiService.get(
        'menuactionrole/list'+
          "?entity_type_role_id=" +
          this.role_id +"&type=doptor"
      )
        .then((response) => {
          let menu = response.data.data;
          localStorage.setItem('menu',JSON.stringify(menu));
          let arrayCopy = menu.map(item => ({ ...item }));
          this.MainMenu=this.listToTree(arrayCopy);
        })
        .catch((response) => {
          console.log(response);
        });
    },
    listToTree(list){
    let map = {};
    let roots = [] as any;
  
    list.forEach((v, i) => {
      map[v.id] = i;
      list[i].pages = [];
    });
  
     list.forEach(v => (v.parentId !== null  ? list[map[v.parentId]].pages.push(v) : roots.push(v)));
    return roots;
    },
   
  },
  setup() {
    const store = useStore();
    const { t, te } = useI18n();
    const route = useRoute();

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };
    //console.log(store.getters.currentUser.id);
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      hasActiveChildren,
      headerMenuIcons,
      MainMenuConfig,
      translate,
      version,
    };
  },
});
